import React from "react";
import styles from "./home.module.scss";

const Value = () => {
  return (
    <article className={styles.article3}>
      <div className={styles.home_value_block}>
        <div className={styles.home_value_header}>
          <h1>bankQ BigValue</h1>
          <h3>뱅큐의 경쟁력</h3>
          <p>
            뱅큐는 다양한 능력과 실력을 갖춘<br></br>
            데이터 전문 회사입니다.
          </p>
        </div>
        <div className={styles.home_value_body}>
          <ul>
            <li>
              <div className={styles.value1}></div>
              <p>마이데이터 서비스</p>
            </li>
            <li>
              <div className={styles.value2}></div>
              <p>금융데이터 분석</p>
            </li>
            <li>
              <div className={styles.value3}></div>
              <p>데이터 연구진</p>
            </li>
            <li>
              <div className={styles.value4}></div>
              <p>개발 데이터 전문</p>
            </li>
            <li>
              <div className={styles.value5}></div>
              <p>안정적인 설계</p>
            </li>
            <li>
              <div className={styles.value6}></div>
              <p>데이터 정확성</p>
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
};

export default Value;
