/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Slider from "react-slick";
import styles from "./home.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import news1 from "../../assets/images/home/news1.png";
import news2 from "../../assets/images/home/news2.png";
import news3 from "../../assets/images/home/news3.png";
import styled from "styled-components";

const News = () => {
  const settings = {
    responsive: [
      {
        breakpoint: 880,
        settings: {
          infinite: true,
          slidesToShow: 1,
          dots: false,
          arrows: false,

          variableWidth: true,
        },
      },
      {
        breakpoint: 9999,
        settings: "unslick",
      },
    ],
  };

  const StyledSlider = styled(Slider)`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .slick-slide > div {
      margin-right: 20px;
    }
  `;
  return (
    <article className={styles.article4}>
      <div className={styles.home_news_wrapper}>
        <h1>bankQ NEWS</h1>
        <div className={styles.home_news_block}>
          <StyledSlider {...settings}>
            <div className={styles.news_item}>
              <span>보도자료</span>
              <h4>
                뱅큐, 롤텍<br></br>
                마이데이터 클라우드<br></br>
                보안 위한 업무 협약 체결
              </h4>
              <p>2024.09.05</p>
              <div>
                <img src={news1} alt="" />
              </div>
              <a
                href="https://www.dailysecu.com/news/articleView.html?idxno=149309"
                alt=""
                target="_blank"
                rel="noreferrer noopener"
              ></a>
            </div>
            <div className={styles.news_item}>
              <span>보도자료</span>
              <h4>
                인슈로보<br></br>
                보험추천플랫폼 개발 <br></br>
                MOU 체결
              </h4>
              <p>2024.09.05</p>
              <div>
                <img src={news2} alt="" />
              </div>
              <a
                href="https://www.kbanker.co.kr/news/articleView.html?idxno=211017"
                alt=""
                target="_blank"
                rel="noreferrer noopener"
              ></a>
            </div>
            <div className={styles.news_item}>
              <span>보도자료</span>
              <h4>
                마이데이터 기업 <br></br>
                ‘뱅큐’<br></br>
                김원석 대표 취임
              </h4>
              <p>2024.09.05</p>
              <div>
                <img src={news3} alt="" />
              </div>
              <a
                href="https://www.dailysecu.com/news/articleView.html?idxno=146718"
                alt=""
                target="_blank"
                rel="noreferrer noopener"
              ></a>
            </div>
          </StyledSlider>
        </div>
      </div>
    </article>
  );
};

export default News;
