import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router";
import Footer from "./Footer";
import styles from "./layout.module.scss";

import ScrollToTop from "routes/ScrollToTop";

const RootLayout = () => {
  const [showButton, setShowButton] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const handleShowButton = () => {
      setShowButton(window.scrollY > 500);
    };

    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <div
        className={
          showButton
            ? `${styles.up_btn_active} ${styles.up_btn}`
            : `${styles.up_btn}`
        }
        onClick={handleScrollToTop}
      ></div>
      <div className={styles.container}>
        <Header />
        <main className={styles.body}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default RootLayout;
