import About from "pages/about";
import Contact from "pages/contact";
import Home from "pages/home";
import Service from "pages/service";
import RootLayout from "layouts/RootLayout";
import { createBrowserRouter } from "react-router-dom";
import Reference from "pages/reference";
import { Privacy, ServiceTerms } from "pages/terms";

const routerInfo = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "service",
        element: <Service />,
      },
      {
        path: "reference",
        element: <Reference />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "terms",
        children: [
          {
            path: "privacy",
            element: <Privacy />,
          },
          {
            path: "service",
            element: <ServiceTerms />,
          },
        ],
      },
    ],
  },
];

const Router = createBrowserRouter(routerInfo);

export default Router;
