import React from "react";
import styles from "./home.module.scss";
import { Link } from "react-router-dom";
import service1 from "../../assets/images/home/service1.png";
import service2 from "../../assets/images/home/service2.png";
import service3 from "../../assets/images/home/service3.png";

const Service = () => {
  return (
    <article className={styles.article2}>
      <h1>bankQ SERVICE</h1>
      <div className={styles.home_service_block}>
        <div className={`${styles.home_service_box} ${styles.text_box}`}>
          <h5>마이데이터 서비스 '베핀'</h5>
          <p>
            사용자 사용자의 금융자산, 거래를 한번에 조회할 수 있는 서비스입니다.
            <br></br>
            <br></br>
            {/* 사용자의 소비 데이터를 분석하여 최적의 신용카드를 추천하고<br></br>
            매주 소비 리포트(소비케어)를 통해 합리적인 소비생활 유도<br></br>
            KB증권과의 협업으로 보유 펀드를 분석하여 리포트(펀드케어)
            제공합니다. */}
          </p>
          <div className={styles.home_service_btns}>
            <Link to={"/service"}>
              <button className={styles.btn_white}>자세히 보기</button>
            </Link>
            <Link to={"/contact"}>
              <button>상담 신청</button>
            </Link>
          </div>
        </div>
        <div className={`${styles.home_service_box} ${styles.img_box}`}>
          <img src={service1} alt="" />
        </div>
      </div>
      <div className={styles.home_service_block2}>
        <div className={`${styles.home_service_box} ${styles.text_box}`}>
          <h5>금융데이터 분석 알고리즘 제공</h5>
          <p>
            뱅큐가 수년간 축적한 마이데이터 기술을 바탕으로 최고의 솔루션
            서비스를 제공합니다. 뱅큐는 최신 기술과 첨단 알고리즘을 활용하여
            금융데이터를 분석하고 가치 있는 통찰력을 제공합니다. 고객은 우리의
            서비스를 통해 정확한 예측과 전략적 결정을 할 수 있습니다. 우리의
            알고리즘은 신속하고 정확한 결과를 제공하여 시장 변동에 민첩하게
            대응할 수 있도록 도와줍니다.
          </p>
          <div className={styles.home_service_btns}>
            <Link to={"/service"}>
              <button className={styles.btn_white}>자세히 보기</button>
            </Link>
            <Link to={"/contact"}>
              <button>상담 신청</button>
            </Link>
          </div>
        </div>
        <div className={`${styles.home_service_box} ${styles.img_box}`}>
          <img src={service2} alt="" />
        </div>
      </div>
      <div className={styles.home_service_block}>
        <div className={`${styles.home_service_box} ${styles.text_box}`}>
          <h5>APP / WEB 홈페이지 제작</h5>
          <p>
            뱅큐는 고객의 개성을 반영하고 최신 기술을 활용하여 웹 및 앱
            홈페이지를 제작합니다. 또한 사용자 경험과 시각적 디자인에 중점을
            두며, 고객의 요구 사항을 충족시키는 맞춤형 솔루션을 제공합니다.
            효율적이고 혁신적인 디자인과 개발 과정을 통해 고객의 비즈니스 목표를
            달성하는 데 도움을 드립니다. 우리의 전문가 팀은 프로젝트의 모든
            단계에서 고객과 긴밀하게 협력하여 최고의 결과물을 보장합니다.
          </p>
          <div className={styles.home_service_btns}>
            <Link to={"/service"}>
              <button className={styles.btn_white}>자세히 보기</button>
            </Link>
            <Link to={"/contact"}>
              <button>상담 신청</button>
            </Link>
          </div>
        </div>
        <div className={`${styles.home_service_box} ${styles.img_box}`}>
          <img src={service3} alt="" />
        </div>
      </div>
    </article>
  );
};

export default Service;
