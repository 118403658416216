import { confirmAlert } from "react-confirm-alert";
import { React, useState } from "react";
import { inRange } from "lodash";
import { createBrowserHistory } from "history";

/**
 *
 * <pre>
 * utils.js
 * 잡다한거 그냥 모아놨습니다.
 * 수정일    수정자     수정내용
 * -------------------------------------------------
 * 2021-04-02 최초생성
 * </pre>
 * @author been7
 * @since 2021-04-02
 * @version 1.0
 *
 * Copyright (C) by HNFincore All right Preserved.
 */

//브라우저 뒤로가기 감지 라이브러리
//(모달 창이 나왔을때 안드로이드 백 버튼을 클릭 하면 모달창이 사라지게 하기 위함)
const history = createBrowserHistory();

export function numComma(x) {
  if (x === undefined || x === null) {
    return "";
  }
  return ("" + x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function printMsg(msg, title = "", action = () => {}) {
  if (msg !== "" && msg !== null) {
    confirmAlert({
      customUI: ({ onClose }) => {
        /** alert창이 떠있는 상태에서 부모 컨테이너 스크롤 금지 */
        document.body.style.cssText = `
                position:fixed; 
                width:100%;
                top: -${window.scrollY}px;
                overflow-y: scroll;
                `;
        const isBack = history.listen(({ action }) => {
          if (action === "POP") {
            onClose();
          }
        });
        return (
          <>
            <div className="alertOverlay" onClick={onClose}></div>
            <div className="modal-wrap open alert" tabIndex="0">
              <div className="modal-content alert">
                {title !== "" && title !== null ? (
                  <div className="modal-title">{title}</div>
                ) : null}
                <div className="modal-cont">
                  <div className="inner mt45">
                    <p>{msg}</p>
                  </div>
                </div>
                <ul className="btnArea">
                  <li className="btn50">
                    <button
                      className="btn blue"
                      onClick={() => {
                        /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                        const scrollY = document.body.style.top;
                        document.body.style.cssText = "";
                        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                        action();
                        onClose();
                      }}
                    >
                      확인
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    });
  }
}

export function confirmMsg(msg, noMsg, yesMsg, yesClick, noClick = () => {}) {
  confirmAlert({
    customUI: ({ onClose }) => {
      /** alert창이 떠있는 상태에서 부모 컨테이너 스크롤 금지 */
      document.body.style.cssText = `
            position:fixed; 
            width:100%;
            top: -${window.scrollY}px;
            overflow-y: scroll;
            `;
      const isBack = history.listen(({ action }) => {
        if (action === "POP") {
          onClose();
        }
      });
      return (
        <>
          <div
            className="overlay"
            onClick={() => {
              /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
              const scrollY = document.body.style.top;
              document.body.style.cssText = "";
              window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
              noClick();
              onClose();
            }}
          ></div>
          <div className="modal-wrap open" tabIndex="0">
            <div className="modal-content pop_mVer w80">
              <div className="modal-cont">
                <div className="coment">
                  {Array.isArray(msg) ? (
                    msg.map((item, i) => (
                      <p className="modal-msgs" key={i}>
                        {item}
                      </p>
                    ))
                  ) : (
                    <p className="modal-msg"> {msg} </p>
                  )}
                </div>
              </div>
              <ul className="btnArea">
                <li className="btn50">
                  <button
                    className="btn gray"
                    style={{ backgroundColor: "gray" }}
                    onClick={() => {
                      /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                      const scrollY = document.body.style.top;
                      document.body.style.cssText = "";
                      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                      noClick();
                      onClose();
                    }}
                  >
                    {noMsg}
                  </button>
                </li>
                <li className="btn50">
                  <button
                    className="btn blue"
                    onClick={() => {
                      /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                      const scrollY = document.body.style.top;
                      document.body.style.cssText = "";
                      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                      yesClick();
                      onClose();
                    }}
                  >
                    {yesMsg}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    },
  });
}

export function actionMsg(msg, noMsg, yesMsg, actionClick, noClick = () => {}) {
  confirmAlert({
    customUI: ({ onClose }) => {
      /** alert창이 떠있는 상태에서 부모 컨테이너 스크롤 금지 */
      document.body.style.cssText = `
            position:fixed; 
            width:100%;
            top: -${window.scrollY}px;
            overflow-y: scroll;
            `;
      const isBack = history.listen(({ action }) => {
        if (action === "POP") {
          onClose();
        }
      });
      return (
        <>
          <div
            className="overlay"
            onClick={() => {
              /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
              const scrollY = document.body.style.top;
              document.body.style.cssText = "";
              window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
              noClick();
              onClose();
            }}
          ></div>
          <div className="modal-wrap open" tabIndex="0">
            <div className="modal-content pop_mVer w80">
              <div className="modal-cont">
                <div className="coment" style={{ padding: "0 20px 32px" }}>
                  <p>{msg}</p>
                </div>
              </div>
              <ul className="btnArea">
                <li className="btn50">
                  <button
                    className="btn gray"
                    style={{ backgroundColor: "gray" }}
                    onClick={() => {
                      /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                      const scrollY = document.body.style.top;
                      document.body.style.cssText = "";
                      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                      noClick();
                      onClose();
                    }}
                  >
                    {noMsg}
                  </button>
                </li>
                <li className="btn50">
                  <button
                    className="btn blue"
                    onClick={() => {
                      /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                      const scrollY = document.body.style.top;
                      document.body.style.cssText = "";
                      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                      actionClick();
                      onClose();
                    }}
                  >
                    {yesMsg}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    },
  });
}

export function successMsg(msg, successClick) {
  confirmAlert({
    customUI: ({ onClose }) => {
      /** alert창이 떠있는 상태에서 부모 컨테이너 스크롤 금지 */
      document.body.style.cssText = `
             position:fixed; 
             width:100%;
             top: -${window.scrollY}px;
             overflow-y: scroll;
             `;
      const isBack = history.listen(({ action }) => {
        if (action === "POP") {
          onClose();
        }
      });
      return (
        <>
          <div className="overlay"></div>
          <div className="modal-wrap open" tabIndex="0">
            <div className="modal-content pop_mVer w80">
              <div className="modal-cont">
                <div className="coment">
                  {Array.isArray(msg) ? (
                    msg.map((item, i) => (
                      <p className="modal-msgs" key={i}>
                        {item}
                      </p>
                    ))
                  ) : (
                    <p className="modal-msg"> {msg} </p>
                  )}
                </div>
              </div>
              <ul className="btnArea">
                <li className="btn50">
                  <button
                    className="btn blue"
                    onClick={() => {
                      /** alert창을 닫으면 다시 부모컨테이너 스크롤 가능 */
                      const scrollY = document.body.style.top;
                      document.body.style.cssText = "";
                      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
                      successClick();
                      onClose();
                    }}
                  >
                    확인
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    },
  });
}

export function token() {
  let returnTkn = [];
  if (
    localStorage.getItem("acc-token") === undefined ||
    localStorage.getItem("acc-token") === null
  ) {
    if (
      localStorage.getItem("ref-token") === undefined ||
      localStorage.getItem("ref-token") === null
    ) {
      return returnTkn;
    } else {
      return returnTkn.push(localStorage.getItem("acc-token"));
    }
  } else {
    return returnTkn.push(localStorage.getItem("acc-token"));
  }
}

//이메일 정규식
export function isEmail(asValue) {
  const regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

//아이디 정규식
export function isLoginId(asValue) {
  if (!isCelluar(asValue)) {
    const regExp = /^[a-z0-9.]{5,49}$/g;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
  } else {
    return true;
  }
}

//비밀번호 체크 정규식
export function isJobPassword(asValue) {
  const regExp =
    /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,12}$/;
  return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

// 핸드폰 번호 체크 정규식
export function isCelluar(asValue) {
  const regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
  return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}
// 생년월일 체크 정규식
export function isBirthDay(asValue) {
  const regExp =
    /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
  return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}
// 핸드폰 번호 체크 정규식
export function setCelluar(asValue) {
  if (asValue === undefined || asValue === "" || asValue === null) {
    return "";
  } else {
    return asValue.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      "$1-$2-$3"
    );
  }
}

//이자계산(원리금 균등상환)
export function getCalculationInterest(price, interestRate, term) {
  const den_1 = (interestRate / 12).toFixed(6) * 0.01; //분모계산 1. 소수점 6째 자리까지 구한후 숫자로 환산
  const den_2 = (1 + den_1) ** term - 1; //분모계산 2  (den_1 + 1) 대출기간 제곱 - 1

  const num_1 = price * den_1 * (1 + den_1) ** term; //분자계산

  const result = Math.round(num_1 / den_2); //월 상환금 (이자 + 납입원금)

  return [den_1, den_2, num_1, result];
}

//이자계산(원금 균등상환)
export function getCalculationPrincipal(price, interestRate, term) {
  const den_1 = Math.round(price / term); // 대출금 / 개월 수 = 원금 금액
  const den_2 = (interestRate / 12).toFixed(6) * 0.01; //이자율 / 개월 수 = 월 이자율
  const num_1 = Math.round(price * den_2); // 이자 금액

  return [den_1, den_2, num_1];
}

//금액 계산
export function setMoney(price) {
  let resPrice = price + "0000";

  return parseInt(resPrice);
}

//날짜 형식 변경 함수 yyyy.mm.dd
export function setDateFormat(date) {
  const dateFormat = date && date.substring(0, 10).replace(/-/gi, ".");

  return dateFormat;
}

//핸드폰 번호 '-' 정규식 함수
export function setMobileHyphen(phone) {
  return phone && phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
}

//생년월일 '-' 함수
export function setBirthHyphen(birth) {
  if (!!birth) {
    return birth.replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3");
  } else {
    return;
  }
}

export function getSeconds(time) {
  const seconds = Number(time % 60);
  if (seconds < 10) {
    return "0" + String(seconds);
  } else {
    return String(seconds);
  }
}

export function isEmpty(input) {
  if (
    typeof input === "undefined" ||
    input === null ||
    input === "" ||
    input === "null" ||
    input.length === 0 ||
    (typeof input === "object" && !Object.keys(input).length)
  ) {
    return true;
  } else return false;
}

export const moneyFormat = (value) => {
  const numbers = [
    numbering(value % 100000000000000000000, 10000000000000000),
    numbering(value % 10000000000000000, 1000000000000),
    numbering(value % 1000000000000, 100000000),
    numbering(value % 100000000, 10000),
    value % 10000,
  ];

  return setUnitText(numbers)
    .filter((number) => !!number)
    .join(" ");
};

const setUnitText = (numbers) => {
  const unit = ["원", "만", "억", "조", "경"];
  return numbers.map((number, index) =>
    !!number ? numberFormat(number) + unit[unit.length - 1 - index] : number
  );
};

const numbering = (value, division) => {
  const result = Math.floor(value / division);
  return result === 0 ? null : result % division;
};
const NUMBER_FORMAT_REGX = /\B(?=(\d{3})+(?!\d))/g;

export function numberFormat(value) {
  return value.toString().replace(NUMBER_FORMAT_REGX, ",");
}

export function getDateFormat() {
  const now = new Date();
  let month = now.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return now.getFullYear() + month;
}
