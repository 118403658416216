import React from "react";
import styles from "./layout.module.scss";
import { NavLink } from "react-router-dom";

const Nav = ({ navArray }) => {
  return (
    <nav>
      <div className={styles.navbar}>
        {navArray.map((nav) => {
          return (
            <NavLink
              key={nav.name}
              className={({ isActive }) => (isActive ? `${styles.active}` : "")}
              to={nav.path}
            >
              {nav.name}
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};

export default Nav;
