import { useEffect } from "react";
import styles from "./contact.module.scss";
const { kakao } = window;

function Kakao() {
  useEffect(() => {
    const container = document.getElementById("map");
    const markerPosition = new kakao.maps.LatLng(37.5281073, 126.9198317);
    const makrer = new kakao.maps.Marker({ position: markerPosition });

    const options = {
      center: new kakao.maps.LatLng(37.5281073, 126.9198317),
      level: 3,
    };
    const map = new kakao.maps.Map(container, options);
    makrer.setMap(map);
  }, []);

  return (
    <div className={styles.map_block}>
      <div id="map" className={styles.map}></div>
    </div>
  );
}

export default Kakao;
