import React, { useState } from "react";
import styles from "./layout.module.scss";
import Nav from "./Nav";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";

const navArray = [
  {
    name: "ABOUT",
    path: "/about",
  },
  {
    name: "SERVICE",
    path: "/service",
  },
  {
    name: "REFERENCE",
    path: "/reference",
  },
  {
    name: "CONTACT",
    path: "/contact",
  },
];

const Header = () => {
  const [isClick, setIsClick] = useState(false);

  return (
    <header>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={Logo} alt="" />
          <Link to="/" />
        </div>

        <Nav navArray={navArray} />
        <div className={styles.menu_icon}>
          <div
            className={`${styles.menu_trigger} ${isClick && styles.active}`}
            onClick={() => {
              setIsClick(!isClick);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {/* <div className={`${styles.dim} ${isClick && styles.active}`}></div> */}
      <div className={`${styles.menu} ${isClick && styles.active}`}>
        <ul>
          {navArray.map((nav) => {
            return (
              <li key={nav.name}>
                <NavLink to={nav.path} onClick={() => setIsClick(false)}>
                  {nav.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </header>
  );
};

export default Header;
