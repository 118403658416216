import React, { useEffect, useState } from "react";
import styles from "./layout.module.scss";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  const [contact, setContact] = useState(true);

  useEffect(() => {
    if (pathname === "/contact") {
      setContact(false);
    } else {
      setContact(true);
    }
  }, [pathname]);

  return (
    <footer>
      {contact && (
        <article className={styles.contact_container}>
          <div className={styles.contact_wrapper}>
            <div className={styles.contact_block}>
              <h1>Contact us</h1>
              <p>
                궁금한 점이 있으신가요?<br></br>문의사항을 남겨주시면 뱅큐
                담당자가 답변드립니다.
              </p>
              <Link to={"/contact"}>
                <button>문의하기</button>
              </Link>
            </div>
          </div>
        </article>
      )}

      <article className={styles.footer_container}>
        <div className={styles.footer_wrapper}>
          <div className={styles.corp_info}>
            <Link to={"/terms/service"}>이용약관</Link>
            <span>|</span>
            <Link to={"/terms/privacy"}>개인정보처리방침</Link>
            <span>|</span>문의&제휴
            <span>|</span> 02-780-0345 (오전 10:00 ~ 오후 4:00) <br></br>
            ㈜뱅큐 <span>|</span>대표 : 김원석 <span>|</span>사업자등록번호 :
            404-88-01311 <span>|</span>통신판매업신고 : 2019-서울영등포-2066
            <br></br>주소 : 서울 영등포구 국회대로66번길 23, 6층 602호(여의도동,
            산정빌딩)
            <br></br>
            <br></br>
            Copyright © bankQ. All rights reserved.
          </div>
          {/* <div className={styles.app}>앱 다운로드 경로</div> */}
        </div>
      </article>
    </footer>
  );
};

export default Footer;
