import React from "react";
import styles from "./service.module.scss";
import img_service5 from "../../assets/images/img-service5.png";
import icon_arrow from "../../assets/images/icon-double-arrow.png";

const Tab2 = () => {
  return (
    <>
      <article>
        <h5>금융데이터 분석 알고리즘 제공 서비스</h5>
        <p>
          당사는 마이데이터 사업자 중 가장 정확한 소비 분석 알고리즘과 신용카드
          추천 알고리즘을 보유<br></br>
          소비 분석 알고리즘의 경우 다양한 외부 빅데이터를 활용해서 정확도를 95%
          수준에서 유지하고 있음 (네이버 / 카카오의 지리정보,공공데이터,
          사업자정보 등)<br></br>
          정교한 소비 분석과 머신러닝을 활용하여 신용카드 추천 알고리즘을 개발
          <br></br>
          상기 알고리즘은 롯데카드의 설계사용 태블릿에 제공되고 있으며, 향후
          서비스될 롯데카드의 마이데이터 서비스에도 제공
        </p>
        <div className={styles.img_block}>
          <img src={img_service5} alt="" />
        </div>
      </article>
      <article>
        <h5>기본 서비스(소비 분석)와 상품 추천 서비스로 구분</h5>
        <div className={styles.info_block}>
          <ol>
            <li>
              기본서비스(소비분석)
              <div className={styles.info_group}>
                <ul>
                  <li>사용자의 소비 데이터를 분류하여 제공 (자동 가계부)</li>
                  <li>
                    지속적인 외부 빅데이터 업데이트, 데이터 라벨링 등을 통해
                    품질 유지
                  </li>
                </ul>
                <div className={styles.info_icon_box}>
                  <img src={icon_arrow} alt="" />
                </div>
                <ul>
                  <li>사용량(호출) 구간에 따른 월 단위 운영료</li>
                  <li>사용량에 따라 월 단위 변동</li>
                </ul>
              </div>
            </li>
            <li>
              상품 추천 서비스
              <div className={styles.info_group}>
                <ul>
                  <li>사용자의 보유카드 분류 및 매칭</li>
                  <li>신용카드 상품 정보 제공</li>
                  <li>소비 분석 기반 신용카드 추천 서비스</li>
                  <li>페르소나 기반 신용카드 추천 서비스</li>
                </ul>
                <div className={styles.info_icon_box}>
                  <img src={icon_arrow} alt="" />
                </div>
                <ul>
                  <li>사용량(호출) 구간에 따른 월 단위 운영료</li>
                  <li>사용량에 따라 월 단위 변동</li>
                  <li>
                    소비 분석 기반 / 페르소나 기반 선택에 따른 별도 운영료
                  </li>
                </ul>
              </div>
            </li>
            <li>
              기타 서비스
              <div className={styles.info_group}>
                <ul>
                  <li>제휴사의 요청에 따른 서비스 추가 개발</li>
                  <li>별도 요청 서비스 등</li>
                </ul>
                <div className={styles.info_icon_box}>
                  <img src={icon_arrow} alt="" />
                </div>
                <ul>
                  <li>요청 사항에 따른 별도 비용 산정</li>
                </ul>
              </div>
            </li>
          </ol>
        </div>
        <table className={styles.service_table}>
          <thead>
            <tr>
              <th>서비스</th>
              <th>상세 서비스</th>
              <th>서비스 내용</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan={2}>기본 서비스</th>
              <td>소비 분석(분류) API</td>
              <td>
                빅데이터 기반 소비 카테고리 분류 서비스
                <ul>
                  <li>소비 분류</li>
                  <li>자동 가계부</li>
                </ul>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>소비 케어 API</td>
              <td>
                소비 리포트 서비스
                <ul>
                  <li>소비 성향 분석</li>
                  <li>소비 성향 비교(유사 소비 계층)</li>
                </ul>
              </td>
              <td></td>
            </tr>
            <tr>
              <th rowSpan={4}>상품 추천 서비스</th>
              <td>카드 분류(매칭) API</td>
              <td>
                보유 카드 분류 서비스
                <ul>
                  <li>사용자 보유 카드 매칭</li>
                  <li>카드별 간단한 정보 제공</li>
                </ul>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>카드 상품 API</td>
              <td>
                카드 상품 정보 제공 서비스
                <ul>
                  <li>카드 상품 상세 정보 제공</li>
                </ul>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>소비 분석 기반 신용카드 추천 API</td>
              <td>
                소비 분석 기반 신용카드 추천 서비스
                <ul>
                  <li>사용자의 소비 내역에 따른 최적의 신용카드 추천</li>
                  <li>보유상품, 추천상품에 대한 혜택 금액 산출</li>
                </ul>
              </td>
              <td>두가지 중 1가지 선택 가능</td>
            </tr>
            <tr>
              <td>페르소나 기반 신용카드 추천 API</td>
              <td>
                페르소나 기반 신용카드 추천 서비스 보유 카드 분류 서비스
                <ul>
                  <li>머신러닝을 통해 사전에 군집화된 소비그룹 추출</li>
                  <li>해당 소비 그룹에 최적화된 신용카드 추천</li>
                  <li>사용자에게 간단한 설문을 통해 그룹 선별</li>
                </ul>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </article>
    </>
  );
};

export default Tab2;
