import React from "react";
import styles from "./service.module.scss";
import img_service1 from "../../assets/images/img-service1.png";
import img_service2 from "../../assets/images/img-service2.png";
import img_service3 from "../../assets/images/img-service3.png";
import img_service4 from "../../assets/images/img-service4.png";

const Tab1 = () => {
  return (
    <>
      <article>
        <h5>마이데이터 서비스 소개</h5>
        <p>
          개인이 금융사, 관공서, 병원, 빅테크, 통신사 등에 흩어져 있는 자신의
          개인정보를 특정 마이데이터 사업자에게 제공 하고, <br></br>
          이를 토대로 맞춤형 부가 서비스를 받아 볼 수 있도록 하는 비즈니스 모델
        </p>
        <div className={styles.img_block}>
          <img src={img_service1} alt="" />
        </div>
      </article>
      <article>
        <h5>마이데이터 서비스를 위한 필요 기능 - MyData App & 수집 서비스</h5>
        <div className={styles.img_block}>
          <img src={img_service3} alt="" style={{ maxWidth: "900px" }} />
          <img
            src={img_service4}
            alt=""
            style={{ maxWidth: "900px", marginTop: "60px" }}
          />
        </div>
      </article>
      <article>
        <h5>MyData Biz. - Database 구성</h5>
        <div className={styles.img_block}>
          <img src={img_service2} alt="" />
        </div>
      </article>
    </>
  );
};

export default Tab1;
