import React from "react";
import styles from "./about.module.scss";

const History = () => {
  return (
    <div className={styles.story_wrapper}>
      <div className={styles.story_body}>
        <div className={styles.section_header}>
          <h1 className={styles.title}>bankQ STORY</h1>
        </div>
        <section className={styles.story_section}>
          <ol className={styles.story_contents}>
            <li className={styles.story_item}>
              <div>
                <p>2023</p>
                <ul>
                  <li>인슈로보 여행자 보험 비교 추천 서비스 개발</li>
                  <li>
                    배달서비스 공제화 마이데이터 리워드 앱 보험비교 추천 서비스
                    개발
                  </li>
                </ul>
              </div>
            </li>
            <li className={styles.story_item}>
              <div>
                <p>2022</p>
                <ul>
                  <li>AI 바우처 공급기업 선정</li>
                  <li>데이터 바우처 지원사업 공급기업 선정</li>
                </ul>
              </div>
            </li>
            <li className={styles.story_item}>
              <div>
                <p>2021</p>
                <ul>
                  <li>전북창조경제혁신센터, 금융혁신 벤처창업 지원사업 선정</li>
                  <li>롯데카드, 마이데이터 서비스용 카드추천 서비스 공급</li>
                  <li>본인신용정보관리업(마이데이터) 사업자 선정</li>
                </ul>
              </div>
            </li>
            <li className={styles.story_item}>
              <div>
                <p>2020</p>
                <ul>
                  <li>서울산업진흥원, 핀테크 기술사업화 지원 사업 선정</li>
                  <li>핀테크지원센터, 금융 클라우드 지원 사업 선정</li>
                  <li>롯데카드, 설계사용 카드 추천 서비스 공급</li>
                  <li>소비케어 서비스 출시</li>
                  <li>신용카드 추천 서비스 출시</li>
                </ul>
              </div>
            </li>
            <li className={styles.story_item}>
              <div>
                <p>2019</p>
                <ul>
                  <li>매경 ‘올해의 앱’ 종합자산관리 부문 2년 연속 선정 </li>
                  <li>펀드케어 서비스 출시</li>
                  <li>서울시 핀테크랩 입주사 선정 </li>
                  <li>KB증권, 펀드평가서비스 제공 서비스 MOU 체결</li>
                  <li>삼성자산운용, 컨텐츠 교환 게재를 위한 협약 체결</li>
                  <li>한국인터넷진흥원, 핀테크 API개발 지원 사업 선정</li>
                  <li>핀테크지원센터, 지식재산권 보호 지원 사업 선정</li>
                  <li>뱅큐 사업부분 스핀오프(분사)</li>
                </ul>
              </div>
            </li>
            <li className={styles.story_item}>
              <div>
                <p>2018</p>
                <ul>
                  <li>매일경제 ‘올해의 앱’ 종합자산관리 부문 선정</li>
                  <li>뱅큐, iOS 서비스 출시</li>
                  <li>뱅큐, 안드로이드 서비스 출시</li>
                </ul>
              </div>
            </li>
          </ol>
        </section>
      </div>
    </div>
  );
};

export default History;
