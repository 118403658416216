import React from "react";
import styles from "./terms.module.scss";

const Privacy = () => {
  return (
    <div className={styles.terms_container}>
      <div className={styles.terms_contents_body}>
        <p>
          주식회사 뱅큐(이하 “회사” 또는 “뱅큐”라 함)는 이용자의 신용정보를
          포함한 개인정보(이하 “개인신용정보”라 함)를 매우 소중히 생각합니다.
          회사는 신용정보의 이용 및 보호에 관한 법률, 개인정보보호법, 정보통신망
          이용 촉진 및 정보보호 등에 관한 법률 등 관련 법령을 준수하여 이용자의
          개인신용정보에 대한 권익을 보호하고 개인신용정보와 관련한 이용자의
          고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 수립,
          공개합니다. 본 개인정보 처리 방침의 "이용자"는 정보통신망법
          제2조(정의) 제4호에 규정된 정보통신서비스 제공자가 제공하는
          정보통신서비스의 이용자에 한정합니다. 개인정보 처리 방침은 이용자의
          개인신용정보를 안전하게 보호하기 위한 회사의 정책을 알려드리기 위해
          마련되었으며, 이용하고 계신 회사 앱 또는 웹사이트에서 언제든 확인하실
          수 있습니다. 회사는 개인정보 처리 방침을 개정하는 경우, 웹사이트 또는
          앱에 게시하고 개별적으로 공지할 것입니다.
        </p>
        <h3>제1조 (개인신용정보의 처리 목적)</h3>
        회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을
        위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는
        이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등
        필요한 조치를 이행할 것입니다.
        <ul>
          <li>
            ① 이용자 식별, 회원 가입, 본인 확인, 연령 확인, 불량 회원 처리
          </li>
          <li>② 서비스 제공에 관한 계약 이행, 맞춤형 서비스 제공</li>
          <li>③ 신규 서비스의 개발, 이용 기록 분석, 서비스 개선</li>
          <li>④ 상담, 불만 처리, 부정이용 처리, 공지사항 전달</li>
          <li>
            ⑤ 성별, 연령, 주거형태, 소득 정보 등 통계학적 특성 분석에 기반한
            개인 맞춤형 금융 상품 추천 제공
          </li>
          <li>
            ⑥ 이용자가 가입한 금융상품 정보에 기반한 개인 맞춤형 금융 상품 추천
          </li>
          <li>
            ⑦ 뱅큐 및 제휴사의 상품 및 서비스에 대한 광고, 홍보, 프로모션 제공,
            고객 만족도 조사
          </li>
          <li>
            ⑧ 이벤트 행사 안내, 마케팅 및 광고, 이벤트 경품 당첨 결과 안내 및
            상품 배송 등 이용자의 편의 및 참여 기회 제공
          </li>
          <li>⑨ 그 밖에 이용자의 가입 목적 달성을 위해 필요한 처리</li>
        </ul>
        <h3>제2조 (개인신용정보의 수집 항목 및 수집방법)</h3>
        <ul>
          <li>
            ① 수집하는 개인신용정보 항목 :<br></br>
            회사의 서비스 이용자는 개인신용정보 수집과 이용 동의를 거부할 수
            있습니다. 단, 필수 항목의 수집∙이용 동의를 거부할 경우, 회사 서비스
            회원가입 및 이용이 불가합니다. 마케팅 동의 등 선택 동의를 거부하여도
            회사 서비스는 정상적으로 이용할 수 있습니다. 회사는 이용자의
            금융거래 계좌 및 신용카드 부정 사용 발생 우려가 있는 민감한
            금융정보(통장 비밀번호, 신용카드 비밀번호) 및 주민등록번호는 어떤
            경로로도 수집하지 않습니다.
            <ul>
              <li>
                1. 본인인증 : 이름, 생년월일, 성별, 내외국인 정보,
                이동정보통신사 및 이동전화번호, 휴대폰 본인 인증 시 수집되는
                CI/DI 값 등 회원 본인 확인을 위해 필요한 식별자 정보
              </li>
              <li>
                2. 맞춤형 상품 추천 : 앱 설치 정보, 광고 식별자, 사용자가 입력
                또는 선택한: 연 소득, 주거래 카드, 신용카드 사용 금액, 신용카드
                선호 내역, 금융상품 선호 내역, 직장 정보, 사용자가 입력 또는
                선택한: 기대출 정보, 직장 정보, 금융상품 선호 내역
              </li>
              <li>
                3. 금융관리(자산관리, 지출관리 등) : 사용자가 입력 또는 선택한:
                소비 내용 및 금액, 은행/카드사 등 이용자의 전송요구에 따라
                수집하는 회원 보유 금융상품 정보(금융 사명, 금융 상품명,
                예금계좌/대출/펀드 등의 금융상품 종류, 계좌번호, 신용/체크카드
                번호) 및 신용정보, 계좌 잔액, 대출 잔액 및 금리, 입출금 내역,
                카드 결제 내역, 구매 품목 정보, 카드 이용 한도, 단기 카드대출
                이용 내역 및 한도, 카드론 이용 내역 및 한도, 보유 포인트 내역,
                대출 정보 등의 회원 금융 거래 정보, 신용 조회회사로부터 수집한
                신용점수, 신용정보 변동 여부/변동 내역, 타 기관에 의한 신용정보
                조회 내역, 카드 보유현황, 대출 현황, 연체 현황, 연대보증 현황,
                중복가입 확인정보(DI)
              </li>
              <li>
                4. 이벤트 : 이름, 휴대전화 번호 등 이벤트에 따라 차이가
                있습니다.
              </li>
              <li>
                5. 서비스 이용 : 아래와 같음
                <ul>
                  <li>
                    - 이름, 생년월일, 성별, 내외국인 정보, 이동정보통신사 및
                    휴대전화 번호, PIN 번호
                  </li>
                  <li>
                    - 서비스 이용내역(거래 조건, 거래 일시, 거래금액 등 거래
                    설정 및 내역정보), 이용정지 또는 이용해지 기록, 불량 이용
                    기록, 접속 로그, 쿠키, 접속 IP 주소, MAC 주소, UICC-ID,
                    휴대폰 기기 고유번호(UDID, UUID), 광고 식별자(ADID, IDFA),
                    휴대폰 모델명, 휴대폰 제조사, OS 버전 등의 정보가 자동으로
                    생성되어 수집됩니다.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            ② 개인신용정보의 수집방법 : 이용자의 개인신용정보는 아래 방법을 통해
            수집합니다. <br></br>
            회사는 본인신용관리회사로서 이용자의 개인신용정보 전송요구 요청에
            따라 정보제공자에게 해당 정보를 전송받아 서비스를 제공합니다. 회사는
            이용자의 ID/PW, 공인인증서, 인증서 비밀번호를 저장하지 않으며, 해당
            정보는 이용자의 스마트폰 내에만 암호화하여 저장되었다가 서비스 이용
            시에만 사용됩니다.
            <ul style={{ marginTop: "16px" }}>
              <li>
                1. 이용자의 전송요구권 행사에 따른 개인신용정보의 수집
                <ul>
                  <li>
                    1) 회사는 본인신용정보관리회사로서 이용자의 개인(신용)정보
                    전송요구 요청에 따라 정보제공자에게 API방식을 이용하여
                    개인(신용)정보를 요청하고 전송받아 수집할 수 있습니다.
                  </li>
                  <li>
                    2) 이용자는 전송을 요구하는 개인(신용)정보, 전송요구를 받는
                    자, 전송요구에 따라 개인(신용)정보를 제공받는 자, 전송요구의
                    주기, 전송요구의 종료 시점을 특정하여 전송을 요구할 수
                    있습니다.
                  </li>
                  <li>
                    3) 이용자는 전송 요구를 철회할 수 있으며, 철회 시 수집한
                    개인(신용)정보에 대한 삭제를 요구할 수 있습니다.
                  </li>
                  <li>
                    4) 회사는 이용자 본인의 요청이 아닌 경우 전송 요구, 철회를
                    거절하거나 전송을 중단할 수 있습니다.{" "}
                  </li>
                </ul>
              </li>
              <li>2. 이용자가 회사 서비스를 이용하면서 직접 입력</li>
              <li>3. 생성정보 수집 툴을 통한 수집</li>
              <li>4. 회사에서 진행하는 이벤트 참여</li>
              <li>5. 고객센터 문의, 이메일, 전화, 등의 방법을 통한 상담 시</li>
            </ul>
          </li>
        </ul>
        <h3>제3조 (개인신용정보의 처리 및 보유기간)</h3>
        <p>
          회사는 회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의
          개인 정보를 보유 및 이용하게 됩니다.<br></br>
          회원 탈퇴를 요청하거나 개인 정보의 수집 및 이용에 대한 동의를 철회하는
          경우, 수집 및 이용 목적이 달성되거나 보유 및 이용 기간이 종료한 경우
          해당 개인 정보를 지체 없이 파기합니다.<br></br>
          관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이
          관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
        </p>
        <ul>
          <li>
            ① 회사는 고객이 탈회하거나, 고객을 제명하는 경우 권리남용, 악용
            방지, 권리침해/명예훼손 분쟁 및 수사 협조 의뢰에 대비하여 이용계약
            해지일로부터 3년 동안 개인 정보를 보존합니다.
          </li>
          <li>
            ② 회사는 다음의 사유에 해당하는 경우에는 해당 사유의 종료 시까지
            보관합니다.
            <ul>
              <li>
                1. 관계 법령 위반에 따른 수사/조사 등이 진행 중인 경우에는 해당
                수사/조사 종료 시까지
              </li>
              <li>2. 서비스 이용에 따른 채권/채무관계 정산 시까지</li>
              <li>
                3. 재화/서비스 제공 : 재화/서비스 공급 완료 및 요금 결제 정산
                완료 시까지
              </li>
            </ul>
          </li>

          <li>
            ③ 회사는 관련 법령에 의한 정보 보유 사유가 있는 경우 다음의 정보에
            대해서 명시한 기간 동안 보존합니다.
            <ul>
              <li>
                1. 계약 또는 청약철회 등에 관한 기록 보존 근거: 전자상거래
                등에서 소비자 보호에 관한 법률 (보존기간: 5년)
              </li>
              <li>
                2. 대금 결제 및 재화 등의 공급에 관한 기록 보존 근거: 전자상거래
                등에서 소비자 보호에 관한 법률 (보존기간: 5년)
              </li>
              <li>
                3. 회원의 불만 또는 분쟁처리에 관한 기록 보존 근거: 전자상거래
                등에서 소비자 보호에 관한 법률 (보존기간: 3년)
              </li>
              <li>
                4. 표시/광고에 관한 기록 보존 근거: 전자상거래 등에서 소비자
                보호에 관한 법률 (보존기간: 6개월)
              </li>
              <li>
                5. 전자금융에 관한 기록 보존 근거: 전자금융거래법 (보존기간:
                5년)
              </li>

              <li>
                6. 본인 확인에 관한 기록 보존 근거: 통신 비밀 보호법 (보존기간:
                3개월)
              </li>
              <li>
                7. 신용정보 업무처리에 관한 기록 보존 근거: 신용정보의 이용 및
                보호에 관한 법률 (보존기간: 3년)
              </li>
              <li>
                8. 부정 사용 기록 보존 근거: 회사 내부 방침 (보존기간: 10년)
              </li>
              <li>
                9. 대출조건 확인을 위해 제휴 금융사로부터 수집한 정보: 회사 내부
                방침 (보존기간: 10년)
              </li>
            </ul>
          </li>
        </ul>
        <h3>제 4조(개인 정보의 파기 절차 및 방법)</h3>
        <p>
          회사는 원칙적으로 개인 정보 수집 및 이용 목적이 달성된 후에는 해당
          정보를 지체 없이 파기합니다.
        </p>
        <ul>
          <li>
            ① 파기절차: 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된
            후 별도의 데이터베이스로 옮겨져 (종이의 경우 별도의 서류함) 내부
            방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라 (보유 및 이용
            기간 참조) 일정 기간 저장된 후 파기됩니다.
          </li>
          <li>
            ② 파기방법: 별도 데이터베이스로 옮겨진 개인 정보는 법률에 의한
            경우가 아니고서는 다른 목적으로 이용되지 않습니다. <br></br>
            회사는 파기 사유가 발생한 개인신용정보를 선정하고 개인신용정보 보호
            책임자(신용정보관리 보호인)의 승인을 통해 개인신용정보를 파기합니다
            <br></br>
            전자적 파일 형태로 저장된 개인 정보는 기록을 재생할 수 없는 기술적
            방법을 사용하여 삭제합니다. 종이에 출력된 개인 정보는 분쇄기로
            분쇄하거나 소각합니다.
          </li>
        </ul>
        <h3>제5조 (개인신용정보의 제3자 제공)</h3>
        <p>
          회사는 서비스 이용에 필요한 회원의 개인신용정보를 다음의 목적을 위해
          사전에 고객에게 동의를 받은 후 제휴사에 제공합니다. 처리하는
          개인신용정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
          목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할
          것입니다.
        </p>
        <p>
          회사는 서비스 이용에 필요한 최소한의 개인신용정보만을 제3자에게
          제공하고 있습니다. 다만, 다음 경우에 한하여 이용자의 동의 없이
          개인신용정보를 제공할 수 있습니다.
        </p>
        <ul>
          <li>① 다른 법률에 특별한 규정이 있는 경우</li>
          <li>
            ② 기존 동의한 목적 또는 이용 범위 내에서 고객 정보의 정확성,
            최신성을 유지하기 위한 경우
          </li>
          <li>③ 서비스 제공 또는 요금 정산을 위해 필요한 경우</li>
        </ul>
        <h3>제6조 (개인신용정보 처리 위탁)</h3>
        <p>
          회사는 관련 법령에 의거하여 수탁업체를 정기적으로 감독하고 있습니다.
          <br></br>
          위탁업무의 내용이나 수탁자가 변경되는 경우 개인정보 처리 방침을 통해
          공개하겠습니다.
        </p>
        <p>
          회사는 원활한 서비스 제공을 위해 반드시 필요한 경우 업무 중 일부를
          외부 수탁 업체로 하여금 수행하도록 하고 개인신용정보를 위탁하고
          있습니다.
        </p>
        <ul>
          <li>
            ① ㈜코리아크레딧뷰로
            <ul>
              <li>- 위탁업무 : 본인확인 서비스 업무</li>
              <li>
                - 개인정보 : 이름, 생년월일, 성별, 휴대전화번호, 통신사,
                내/외국인 구분
              </li>
            </ul>
          </li>
        </ul>
        <h3>제7조 (이용자 및 법정대리인의 권리 의무 및 그 행사방법)</h3>
        <ul>
          <li>
            ① 개인정보의 열람 및 정정 : <br></br>
            정보의 주체는 회사에 대해 언제든지 아래의 개인신용정보 보호 관련
            권리를 행사할 수 있습니다.
          </li>
          <li>
            ② 개인정보의 수집, 이용, 제공 동의 철회 :<br></br>
            이용자는 언제든지 개인신용정보를 수정하려 하는 경우 설정등의 메뉴를
            통해 변경된 정보로 수정 및 등록이 가능합니다.<br></br>
            동의 철회는 개인정보관리담당자에게 서면, 전화, E-mail을 통해서
            신청할 수 있으며, 담당자는 접수 즉시 동의철회 등록, 개인정보 파기
            등의 조치를 취한 후 해당 사실을 이용자에게 통지합니다.<br></br>
            단, 회사는 만 14 세 미만 아동의 개인신용정보를 보호하기 위해 본
            서비스의 회원가입을 제한하고 있습니다.
          </li>
        </ul>
        <p>
          이용자는 개인신용정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
          예방해 주시기 바랍니다. 입력한 부정확한 정보로 인해 발생하는 사고의
          책임은 이용자에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우
          회원 자격이 상실될 수 있습니다.
        </p>
        <p>
          회사는 타인의 제휴업체 ID 또는 기타 개인신용정보를 도용하여 회원가입
          한 자 또는 서비스를 이용한 자를 발견할 경우 지체 없이 해당 아이디에
          대한 서비스 이용 정지 또는 회원 탈퇴 등 필요한 조치를 취합니다.
        </p>
        <p>
          회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는
          일들에 대해서는 책임을 지지 않습니다. 이용자는 본인의 개인신용정보를
          보호하기 위해 자신의 ID와 비밀번호를 적절하게 관리하고 이에 대한
          책임을 져야 할 의무가 있습니다. 또한 다른 사람이 추측할 수 있는 쉬운
          비밀번호는 사용을 피하시기를 권장하며, 정기적으로 비밀번호를 변경하는
          것이 바람직합니다.
        </p>
        <h3>제8조 (개인신용정보의 안전성 확보조치)</h3>
        <p>
          회사는 이용자의 개인신용정보를 안전하게 관리하기 위하여 최선을 다하며
          신용정보의 이용 및 보호에 관한 법률, 개인정보 보호법, 정보통신망 이용
          촉진 및 정보보호 등에 관한 법률 등 관련 법령에서 요구하는 사항을
          철저히 준수하고 있습니다. 개인신용정보 보호를 위한 기술적, 관리적,
          물리적 대책은 아래와 같습니다.
        </p>
        <ul>
          <li>
            ① 기술적 대책 : 개인정보처리시스템 등의 접근권한 관리,
            접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
          </li>
          <li>② 관리적 대책 : 내부관리계획 수립, 시행, 정기적 직원 교육 등</li>
          <li>
            ③ 물리적 대책 : 회사 전산실(IDC 포함), 자료 보관실 등 물리적
            접근통제를 수행
            <ul>
              <li>
                1. 개인(신용)정보 및 비밀번호에 대한 암호화 관리: <br></br>
                개인정보처리시스템을 통한 고유식별정보 등의 암호화, 보안프로그램
                설치를 통해 사용자의 개인(신용)정보 보호를 위해 노력하고
                있습니다. 회원 아이디(ID)의 비밀번호는 암호화되어 저장 및
                관리되고 있어 본인만이 알고 있으며, 개인(신용)정보의 확인 및
                변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
              </li>
              <li>
                2. 해킹 등에 대비한 대책: <br></br>
                회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
                유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                개인(신용)정보의 훼손에 대비해서 자료를 수시로 백업하고 있고,
                최신 백신프로그램을 이용하여 이용자들의 개인(신용)정보나 자료가
                유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을
                통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
                있습니다.
              </li>
              <li>
                3. 처리 직원의 최소화 및 교육: <br></br>
                회사의 개인(신용)정보관련 처리 직원은 담당자에 한정시키고 있고
                이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며,
                담당자에 대한 수시 교육을 통하여 개인(신용)정보처리방침의 준수를
                항상 강조하고 있습니다.
              </li>
              <li>
                4. 정기적인 자체 감사 실시:<br></br>
                개인(신용)정보 처리 관련 안전성 확보를 위해 정기적(분기 1회)으로
                자체 감사를 실시하고 있습니다.
              </li>
              <li>
                5. 내부관리계획의 수립 및 시행: <br></br>
                개인(신용)정보의 안전한 처리를 위하여 내부관리계획을 수립하여
                시행하고 있습니다.
              </li>
              <li>
                6. 개인(신용)정보에 대한 접근 제한: <br></br>
                개인(신용)정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여
                필요한 조치를 하고 있고, 침입차단시스템을 이용하여 외부로부터의
                무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기
                위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
              </li>
              <li>
                7. 접속기록의 보관 및 위변조 방지: <br></br>
                개인(신용)정보처리시스템에 접속한 기록을 최소 6개월 이상 보관,
                관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록
                보안기능을 사용하고 있습니다.
              </li>
            </ul>
          </li>
        </ul>
        <h3>
          제9조 (개인신용정보 자동수집장치의 설치 운영 및 그 거부에 관한 사항)
        </h3>
        회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를
        저장하고 수시로 불러오는 쿠키(cookie)와 세션(session)을 사용합니다.
        <ul>
          <li>
            ① “쿠키”는 앱 또는 웹사이트 서버가 이용자의 브라우저에 보내는 소량의
            정보이며 이용자들 단말 기기에 저장됩니다.
          </li>
          <li>
            ② 이용자가 앱 또는 웹사이트에 접속한 경우 사이트에서 이용자의 기기에
            저장되어 있는 쿠키 정보를 확인하여 이용자의 이용 형태, 보안접속 여부
            등을 파악하여 이용자에게 최적의 서비스, 광고 등을 제공하기 위해
            사용됩니다.
          </li>
          <li>
            ③ 이용자는 쿠키 설치 운영을 거부할 수 있으며, 저장을 원하지 않으시면
            웹 브라우저의 설정 방법을 통해 쿠키 허용을 제한할 수 있습니다. 단
            쿠키 저장을 제한하는 경우 맞춤형 서비스 이용에 어려움이 발생할 수
            있습니다.
          </li>
        </ul>
        <h3>제10조 (개인정보 보호책임자(신용정보관리 보호인))</h3>
        뱅큐는 이용자의 개인신용정보를 보호하고 개인신용정보와 관련한 고충
        처리를 위하여 다음과 같이 개인 정보보호 책임자, 신용정보관리인 및
        담당부서를 두고 있습니다.
        <ul>
          <li>
            ① 개인신용정보 보호책임자 겸 신용정보관리인
            <ul>
              <li>성명: 전종호</li>
              <li>소속: 주식회사 뱅큐</li>
              <li>이메일: privacy@bankq.co.kr</li>
            </ul>
          </li>
          <li>
            ② 개인신용정보 열람청구 접수/처리 부서
            <ul>
              <li>부서명 : 데이터콘텐츠팀</li>
              <li>이메일 : ask@bankq.co.kr</li>
            </ul>
          </li>
        </ul>
        <h3>제11조 (권익침해 구제방법)</h3>
        이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해 구제, 상담 등을
        문의하실 수 있습니다. 개인정보 침해에 대한 신고나 상담이 필요한 경우에
        아래 기관에 문의하시기 바랍니다.
        <ul>
          <li>① 개인정보 분쟁 조정위원회 (www.kopico.go.kr / 1833-6972)</li>
          <li>
            ② 개인정보 침해 신고센터 (https://privacy.kisa.or.kr / 국번없이 118)
          </li>
          <li>③ 대검찰청 사이버범죄수사단 (www.spo.go.kr / 국번없이 1301)</li>
          <li>
            ④ 경찰청 사이버범죄 신고(https://cyberbureau.police.go.kr / 국번없이
            182){" "}
          </li>
        </ul>
        <p>부 칙</p>
        <p>본 약관은 2023년 07월 03일부터 적용됩니다.</p>
      </div>
    </div>
  );
};

export default Privacy;
