import React from "react";
import styles from "./about.module.scss";
import AboutImg from "../../assets/images/about/about.png";

const Info = () => {
  return (
    <div className={styles.about_wrapper}>
      <div className={styles.about_body}>
        <div className={styles.section_header}>
          <h1 className={styles.title}>bankQ ABOUT</h1>
        </div>

        <section className={styles.about_section}>
          <div className={styles.about_intro_group}>
            <img src={AboutImg} alt="" />
          </div>
          <div className={styles.about_desc_group}>
            <p>
              <b>
                금융정보의 비대칭성을 해소하여 누구에게나 쉬운 금융을 만드는 일
                <br></br>
                데이터로 실질적인 혜택을 받을 수 있는 금융을 만드는 일 <br></br>
                뱅큐가 추구하는 가치와 이상이며, 뱅큐의 존재 이유입니다.
              </b>
            </p>

            <p>
              뱅큐는 나의 금융생할을 한곳에서 손쉽게 관리할 수 있는 모바일
              개인자산 관리(PFM) 서비스입니다. <br></br>
              마이데이터 사업자로 모바일 자산관리 서비스 ‘베핀’를 서비스하고
              있으며, 금융 데이터 분석 알고리즘을 B2B 형태로 제공하고 있습니다.{" "}
              <br></br>
              {/* 펀드 투자 관리(펀드케어), P2P투자 통합 조회, 신용카드 추천등
              단순한 조회를 넘어서는 강력한 자산괸리 기능들을 제공합니다. */}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Info;
