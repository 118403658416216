import React, { useRef, useMemo, useState, useEffect } from "react";
import styles from "./contact.module.scss";
import Privacy from "pages/terms/Privacy";

import axiosInstance from "pages/lib/apis/AxiosInstancs";
import { confirmAlert } from "react-confirm-alert";
import { isEmail } from "utils/utils";
import Kakao from "./Kakao";

const Contact = () => {
  const [companyNm, setCompanyNm] = useState("");
  const [nm, setNm] = useState("");
  const [contacts, setContacts] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  //회사명
  const companyInput = (e) => {
    setCompanyNm(e.target.value);
  };

  //이름
  const nmInput = (e) => {
    setNm(e.target.value);
  };

  //전화번호
  const contatctsInput = (e) => {
    setContacts(e.target.value);
  };

  //이메일
  const emailInput = (e) => {
    setEmail(e.target.value);
  };

  //내용
  const messageInput = (e) => {
    setMessage(e.target.value);
  };

  const contactSubmit = async () => {
    
    if (email === "" || undefined) {
      alert("이메일은 필수 입력사항입니다.");
      return false;
    }
    
    if (isEmail(email) == false) {
      alert("올바른 이메일 주소를 입력해주세요.");
      return false;
    }

    if (companyNm === "" || undefined) {
      alert("회사이름은 필수 입력사항입니다.");
      return false;
    }

    if (nm === "" || undefined) {
      alert("이름은 필수 입력사항입니다.");
      return false;
    }
    
    let params = {
      companyNm: companyNm,
      nm: nm,
      contacts: contacts,
      message: message,
      toEmail: email,
    };
      
      return await axiosInstance.post("/contact/email", params)
      .then(alert("접수 되었습니다."))
      .then(window.location.replace("/"));
    
  };

  return (
    <div className={styles.container}>
      <div className={styles.contact_wrapper}>
        <div className={styles.section_header}>
          <h1 className={styles.title}>bankQ CONTACT</h1>
        </div>
        <section>
          <div className={styles.contact_section}>
            <form action="" onSubmit={handleSubmit}>
              <div className={styles.contact_group}>
                <article>
                  <h6>오시는 길</h6>
                  <Kakao></Kakao>
                </article>

                <article>
                  <div className={styles.form_group}>
                    <label htmlFor="company">회사명</label>
                    <input
                      type="text"
                      id="company"
                      name=""
                      placeholder="회사명을 입력해주세요."
                      onChange={companyInput}
                    ></input>
                  </div>
                  <div className={styles.form_group}>
                    <label htmlFor="name">이름</label>
                    <input
                      type="text"
                      id="name"
                      name=""
                      placeholder="이름을 입력해주세요."
                      onChange={nmInput}
                    ></input>
                  </div>
                  <div className={styles.form_group}>
                    <label htmlFor="tel">연락처</label>
                    <input
                      type="text"
                      id="tel"
                      name=""
                      placeholder="'-'를 포함해서 입력해주세요."
                      onChange={contatctsInput}
                    ></input>
                  </div>
                  <div className={styles.form_group}>
                    <label htmlFor="email">이메일</label>
                    <input
                      type="text"
                      id="email"
                      name=""
                      placeholder="이메일을 입력해주세요."
                      onChange={emailInput}
                    ></input>
                  </div>
                  <div className={styles.form_group}>
                    <label htmlFor="contents">문의사항</label>
                    <textarea
                      id="contents"
                      name=""
                      placeholder="문의 내용을 입력해주세요."
                      onChange={messageInput}
                    />
                  </div>
                </article>
              </div>

              <article>
                <div className={styles.form_group2}>
                  <h6>개인정보처리방침</h6>
                  <div className={styles.terms_box}>
                    <Privacy />
                  </div>
                </div>
                <div className={styles.form_group3}>
                  <input type="checkbox" id="privacy"></input>
                  <label htmlFor="privacy" style={{ display: "inline-block" }}>
                    개인정보 수집 및 이용 동의
                  </label>
                </div>
                <div className={styles.form_footer}>
                  <button onClick={contactSubmit}>문의하기</button>
                  <div className={styles.cs_box}>
                    <h6>고객센터</h6>
                    <p>전화 : 02-780-0345 (AM 10:00~ PM 04:00)</p>
                    <p>이메일 : contact@bankq.co.kr</p>
                  </div>
                </div>
              </article>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
