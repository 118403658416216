import React from "react";
import styles from "./about.module.scss";
import History from "./History";
import Info from "./Info";

const About = () => {
  return (
    <div className={styles.container}>
      <Info />
      <History />
    </div>
  );
};

export default About;
