import React from "react";
import styles from "./service.module.scss";
import img_homepage1 from "../../assets/images/homepage1.png";
import img_homepage2 from "../../assets/images/homepage2.png";
import img_homepage3 from "../../assets/images/homepage3.png";
import img_homepage4 from "../../assets/images/homepage4.png";
import img_homepage5 from "../../assets/images/homepage5.png";

const Tab3 = () => {
  return (
    <>
      <article>
        <h5>APP / WEB 홈페이지 제작</h5>
        <p>
          뱅큐는 고객의 개성을 반영하고 최신 기술을 활용하여 웹 및 앱 홈페이지를
          제작합니다. <br></br>
          또한 사용자 경험과 시각적 디자인에 중점을 두며, 고객의 요구 사항을
          충족시키는 맞춤형 솔루션을 제공합니다. <br></br>
          효율적이고 혁신적인 디자인과 개발 과정을 통해 고객의 비즈니스 목표를
          달성하는 데 도움을 드립니다. <br></br>
          우리의 전문가 팀은 프로젝트의 모든 단계에서 고객과 긴밀하게 협력하여
          최고의 결과물을 보장합니다.
        </p>
        <div className={styles.img_block2}>
          <img src={img_homepage1} alt="" />
          <img src={img_homepage2} alt="" />
          <img src={img_homepage3} alt="" />
          <img src={img_homepage4} alt="" />
          <img src={img_homepage5} alt="" />
        </div>
      </article>
    </>
  );
};

export default Tab3;
