import React, { useEffect, useState } from "react";
import styles from "./service.module.scss";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import { useSearchParams } from "react-router-dom";

const Service = () => {
  const [tabInfo, setTabInfo] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = (tab) => {
    setTabInfo(tab);
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get("tab") !== null) {
      setTabInfo(searchParams.get("tab"));
    } else {
      setTabInfo("tab1");
    }
  }, [searchParams]);

  const handleTabInfo = (e) => {
    const targetNode = e.target;
    if (targetNode.tagName.toUpperCase() === "LI") {
      setTab(targetNode.dataset.tab);
    }
  };

  return (
    <div className={styles.service_container}>
      <div className={styles.service_wrapper}>
        <div className={styles.section_header}>
          <h1 className={styles.title}>bankQ SERVICE</h1>
        </div>

        <section className={styles.service_section}>
          <div className={styles.service_tab_header}>
            <ul onClick={(e) => handleTabInfo(e)}>
              <li
                data-tab="tab1"
                className={`${tabInfo}` === "tab1" ? `${styles.active}` : ""}
              >
                마이데이터
              </li>
              <li
                data-tab="tab2"
                className={tabInfo === "tab2" ? `${styles.active}` : ""}
              >
                금융데이터
              </li>
              <li
                data-tab="tab3"
                className={tabInfo === "tab3" ? `${styles.active}` : ""}
              >
                APP / WEB
              </li>
            </ul>
          </div>
          <div className={styles.service_tab_body}>
            <section className={styles.service_section}>
              {tabInfo === "tab1" && <Tab1 />}
              {tabInfo === "tab2" && <Tab2 />}
              {tabInfo === "tab3" && <Tab3 />}
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;
