import React from "react";
import styles from "./reference.module.scss";
import img_reference1 from "../../assets/images/img-reference1.png";
import img_reference2 from "../../assets/images/img-reference2.png";
import img_reference3 from "../../assets/images/img-reference3.png";
import img_reference4 from "../../assets/images/img-reference4.png";
import img_homepage1 from "../../assets/images/homepage1.png";
import img_homepage2 from "../../assets/images/homepage2.png";
import img_homepage3 from "../../assets/images/homepage3.png";
import img_homepage4 from "../../assets/images/homepage4.png";
import img_homepage5 from "../../assets/images/homepage5.png";

const Reference = () => {
  return (
    <div className={styles.reference_container}>
      <div className={styles.reference_wrapper}>
        <div className={styles.section_header}>
          <h1 className={styles.title}>bankQ REFERENCE</h1>
        </div>
        <section className={styles.reference_section}>
          <article>
            <h5>앱 개발</h5>
            <p>
              - 라이더 마이데이터 리워드 앱 보험비교 추천 서비스<br></br> -
              여행자 보험 비교 추천 서비스 개발
            </p>
            <div className={styles.img_block}>
              <div>
                <img src={img_reference1} alt="" />
              </div>
              <div>
                <img src={img_reference2} alt="" />
              </div>
            </div>
          </article>
          <article>
            <h5>마이데이터 API 서비스</h5>
            <p>카드, 보험, 펀드, 주식 등 다양한 마이데이터 API 서비스 개발</p>
            <div className={styles.img_block} style={{ marginTop: "40px" }}>
              <div>
                <img
                  src={img_reference3}
                  alt=""
                  style={{ maxHeight: "522px" }}
                />
              </div>
              <div>
                <img
                  src={img_reference4}
                  alt=""
                  style={{ maxHeight: "522px" }}
                />
              </div>
            </div>
          </article>
          <article>
            <h5>홈페이지 제작</h5>
            <p>
              고객의 개성을 반영하고 최신 기술을 활용하여 홈페이지 및 앱을
              제작합니다.
            </p>
            <div className={styles.img_block2}>
              <img src={img_homepage1} alt="" />
              <img src={img_homepage2} alt="" />
              <img src={img_homepage3} alt="" />
              <img src={img_homepage4} alt="" />
              <img src={img_homepage5} alt="" />
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default Reference;
