import React from "react";
import styles from "./home.module.scss";
import Service from "./Service";
import Value from "./Value";
import News from "./News";
import Video from "../../assets/images/home/video.mp4";
import Cover from "../../assets/images/home/cover1-m.png";

const Home = () => {
  return (
    <div className={styles.home_container}>
      <section className={styles.home_wrapper}>
        <article className={styles.article1}>
          <video muted autoPlay loop>
            <source src={Video} type="video/mp4" />
          </video>
          <img src={Cover} alt="" />
        </article>
        <Service />
        <Value />
        <News />
      </section>
    </div>
  );
};

export default Home;
